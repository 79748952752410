import { Pipe, PipeTransform } from '@angular/core';

import { albums } from '../data';
import { Album } from '../interfaces/album.interface';

@Pipe({
  name: 'mac'
})
export class MacPipe implements PipeTransform {

  transform(value: string): string {
    const aliases: string[] = [
      'Easy Mac',
      'EZ Mac',
      'The Too Green Scene',
      'The Ill Spoken',
      'Mac Miller',
      'Luther Hucklebee',
      'Larry Fisherman',
      'Larry Dollaz',
      "MostDope",
      'The Dyson',
      'Larry Lovestein',
      'The Velvet Revival',
      'Delusional Thomas',
      'King Ralph of Malibu',
      'Smoke Mo\' Purp',
      'Super Weirdo',
      'Parson Brown'
    ];

    const albumsList: string[] = albums.map((album: Album) => album.title);

    if (aliases.includes(value) || albumsList.includes(value)) {
      return `<b>${value}</b>`
    }

    return value;
  }

}
