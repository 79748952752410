import { Pipe, PipeTransform } from '@angular/core';
import { TrackType } from '../types/track.type';

@Pipe({
  name: 'trackType'
})
export class TrackTypePipe implements PipeTransform {

  transform(trackType: TrackType|string): string {
    if (trackType === 'Single') {
      return 'Loosie / Single';
    }

    if (trackType === 'Feature') {
      return 'Guest Appearance';
    }

    if (trackType === 'Compilation') {
      return 'Compilation Mixtape';
    }

    if (trackType === 'Video') {
      return 'Music Video';
    }

    return trackType;
  }

}
