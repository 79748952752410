import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceSeparator'
})
export class ReplaceSeparatorPipe implements PipeTransform {

  transform(value: string): any {
    value = value
      .replace(/;([^;]*)$/,'\ & $1') // replace last `;` with ` & `
      .replace(/;/g, ', ');          // replace remaining `;`with `, `

    return value;
  }

}
