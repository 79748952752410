import { Album } from './interfaces/album.interface';
import { VirtualAlbum } from './interfaces/virtual-album.interface';

export const albums: Album[] = [
  {
    cover: 'flipped.jpg',
    date: '2024-01-19',
    albumArtist: 'DJ Clockwork',
    title: '!FLIPPED!',
    type: 'Mixtape',
    sortKey: 770,
    additionalInfo: 'A future beats/black tropical house/drum & bass/tech house/breakbeat mix. This is the 3rd installment of CLOCKWORKDJ\'s yearly tribute mix.',
  },
  {
    cover: 'tiny-desk.jpg',
    date: '2023-08-03',
    albumArtist: 'Mac Miller',
    title: 'NPR Music Tiny Desk Concert',
    type: 'EP',
    sortKey: 740,
    additionalInfo: 'NPR Music Tiny Desk Concert vinyl, featuring the live recording of the August 6, 2018 performance from NPR Headquarters in Washington, D.C.'
  },
  {
    cover: 'mixxed.jpg',
    date: '2023-01-19',
    albumArtist: 'DJ Clockwork',
    title: 'MIXXED: A Collage of Mac\'s Best Raps',
    type: 'Mixtape',
    sortKey: 720,
    additionalInfo: 'This is the 2nd installment of CLOCKWORKDJ\'s yearly tribute mix.',
  },
  {
    cover: 'a-mac-miller-story-remixxed.jpg',
    date: '2021-01-19',
    albumArtist: 'DJ Clockwork',
    title: 'A Mac Miller Story Remixxed',
    type: 'Mixtape',
    sortKey: 680,
    additionalInfo: 'A Collection of Mac\'s Hits Remixxed!<br><br>Musical Interludes Provided By: Innerview Q, Willy Whipz (Mac\'s 1st Tour Manager), Thundercat, Peanut, Bill Waves (Daily Bread).'
  },
  {
    cover: 'circles.jpg',
    date: '2020-01-17',
    albumArtist: 'Mac Miller',
    title: 'Circles',
    type: 'Album',
    sortKey: 650,
    additionalInfo: 'The deluxe version of Circles was released on March 6, 2020.'
  },
  {
    cover: 'spotify-singles.jpg',
    date: '2018-11-28',
    albumArtist: 'Mac Miller',
    title: 'Spotify Singles',
    type: 'EP',
    sortKey: 610
  },
  {
    cover: 'swimming.jpg',
    date: '2018-08-03',
    albumArtist: 'Mac Miller',
    title: 'Swimming',
    type: 'Album',
    sortKey: 590
  },
  {
    cover: 'small-worlds-buttons-programs.png',
    date: '2018-05-30',
    albumArtist: 'Mac Miller',
    title: 'Small Worlds - Buttons - Programs',
    type: 'EP',
    sortKey: 570
  },
  /*{
    cover: 'guidelines.jpg',
    date: '2017-07-01',
    albumArtist: 'Mac Miller',
    title: 'Guidelines',
    type: 'Album',
    sortKey: 569
  },*/
  {
    cover: 'divine-feminine.jpg',
    date: '2016-09-16',
    albumArtist: 'Mac Miller',
    title: 'The Divine Feminine',
    type: 'Album',
    sortKey: 530
  },
  {
    cover: 'run-on-sentences-2.jpg',
    date: '2015-12-30',
    albumArtist: 'Larry Fisherman',
    title: 'Run-On Sentences, Vol. 2',
    type: 'Mixtape',
    sortKey: 500
  },
  {
    cover: 'good-am.jpg',
    date: '2015-09-18',
    albumArtist: 'Mac Miller',
    title: 'GO:OD AM',
    type: 'Album',
    sortKey: 480
  },
  {
    cover: 'faces.jpg',
    date: '2014-05-11',
    albumArtist: 'Mac Miller',
    title: 'Faces',
    type: 'Mixtape',
    additionalInfo: 'Faces was commercially released on streaming platforms and vinyl on October 15, 2021. The streaming version of Faces features a new song <i>Yeah</i>.',
    sortKey: 450
  },
  {
    cover: 'balloonerism.jpg',
    date: '2014',
    albumArtist: 'Mac Miller',
    title: 'Balloonerism',
    type: 'Mixtape',
    additionalInfo: 'Balloonerism was leaked between February and July 2020.',
    sortKey: 449
  },
  {
    cover: 'your-shoes-are-untied.jpg',
    date: '2013',
    albumArtist: 'Mac Miller;BADBADNOTGOOD',
    title: 'Your Shoes Are Untied',
    type: 'EP',
    additionalInfo: 'Your Shoes Are Untied was leaked in late July / early August 2020.',
    sortKey: 435
  },
  {
    cover: 'live-from-space.jpg',
    date: '2013-12-17',
    albumArtist: 'Mac Miller',
    title: 'Live from Space',
    type: 'Live Album',
    sortKey: 420
  },
  {
    cover: 'delusional-thomas.jpg',
    date: '2013-11-01',
    albumArtist: 'Delusional Thomas',
    title: 'Delusional Thomas',
    type: 'Mixtape',
    sortKey: 400
  },
  {
    cover: 'stolen-youth.jpg',
    date: '2013-06-20',
    albumArtist: 'Vince Staples;Larry Fisherman',
    title: 'Stolen Youth',
    type: 'Mixtape',
    sortKey: 380
  },
  {
    cover: 'watching-movies.jpg',
    date: '2013-06-18',
    albumArtist: 'Mac Miller',
    title: 'Watching Movies with the Sound Off',
    type: 'Album',
    additionalInfo: 'Watching Movies with the Sound Off was re-released on June 23, 2023 celebrating its tenth anniversary.<br><br>The anniversary version features a new song <i>The Star Room (Original Version)</i>. The vinyl version features an additional new song <i>The Quest</i>.',
    sortKey: 360
  },
  {
    cover: 'live-from-london.jpg',
    date: '2013-06-10',
    albumArtist: 'Mac Miller',
    title: 'Live from London',
    type: 'Live Album',
    sortKey: 340
  },
  {
    cover: 'run-on-sentences-1.jpg',
    date: '2013-03-04',
    albumArtist: 'Larry Fisherman',
    title: 'Run-On Sentences, Vol. 1',
    type: 'Mixtape',
    sortKey: 320
  },
  {
    cover: 'you.jpg',
    date: '2012-11-21',
    albumArtist: 'Larry Lovestein;The Velvet Revival',
    title: 'You',
    type: 'EP',
    additionalInfo: 'You was commercially released on vinyl on April 22, 2023.<br><br>There is an alternate version of the EP where the last quatrain of the first verse in <i>Suspicions</i> is missing.',
    sortKey: 290
  },
  {
    cover: 'macadelic.jpg',
    date: '2012-03-23',
    albumArtist: 'Mac Miller',
    title: 'Macadelic',
    type: 'Mixtape',
    additionalInfo: 'Macadelic was remastered and commercially released on May 4, 2018.',
    sortKey: 270
  },
  {
    cover: 'black-friday.jpg',
    date: '2011-11-25',
    albumArtist: 'Mac Miller',
    title: 'Black Friday',
    type: 'Mixtape',
    additionalInfo: 'Black Friday is a collection of unreleased songs Mac made in 2008 and 2009. Hosted by Dj CapCom.',
    sortKey: 250
  },
  {
    cover: 'blue-slide-park.jpg',
    date: '2011-11-08',
    albumArtist: 'Mac Miller',
    title: 'Blue Slide Park',
    type: 'Album',
    sortKey: 240
  },
  {
    cover: 'i-love-life.jpg',
    date: '2011-10-14',
    albumArtist: 'Mac Miller',
    title: 'I Love Life, Thank You',
    type: 'Mixtape',
    additionalInfo: 'I Love Life, Thank You was re-released to all streaming services on July 22, 2022.',
    sortKey: 220
  },
  {
    cover: 'on-and-on-and-beyond.jpg',
    date: '2011-03-29',
    albumArtist: 'Mac Miller',
    title: 'On and On and Beyond',
    type: 'EP',
    sortKey: 200
  },
  {
    cover: 'best-day-ever.jpg',
    date: '2011-03-11',
    albumArtist: 'Mac Miller',
    title: 'Best Day Ever',
    type: 'Mixtape',
    additionalInfo: 'Best Day Ever was remastered and commercially released on June 3, 2016 for its fifth anniversary.',
    sortKey: 180
  },
  {
    cover: 'kids.jpg',
    date: '2010-08-13',
    albumArtist: 'Mac Miller',
    title: 'K.I.D.S. (Kickin\' Incredibly Dope Shit)',
    type: 'Mixtape',
    additionalInfo: 'K.I.D.S. was re-released on streaming services on April 29, 2020 without <i>Traffic in the Sky</i> and <i>La La La La</i>.<br><br>Celebrating its tenth anniversary, the digital deluxe version of K.I.D.S. was released on August 13, 2020 featuring two new songs <i>Ayye</i> and <i>Back In The Day</i>.',
    sortKey: 150
  },
  {
    cover: 'the-high-life.jpg',
    date: '2009-12-01',
    albumArtist: 'Mac Miller',
    title: 'The High Life',
    type: 'Mixtape',
    sortKey: 120
  },
  {
    cover: 'the-jukebox.jpg',
    date: '2009-06-01',
    albumArtist: 'Mac Miller',
    title: 'The Jukebox: Prelude to Class Clown',
    type: 'Mixtape',
    sortKey: 100
  },
  {
    cover: 'how-high-mixtape.jpg',
    date: '2008-10-27',
    albumArtist: 'The Ill Spoken',
    title: 'How High: The Mixtape',
    type: 'Mixtape',
    sortKey: 70
  },
  {
    cover: 'how-high-ep.jpg',
    date: '2008-09-12',
    albumArtist: 'The Ill Spoken',
    title: 'How High: The EP',
    type: 'EP',
    sortKey: 50
  },
  {
    cover: 'but-my-mackin-aint-easy.jpg',
    date: '2007',
    albumArtist: 'Easy Mac',
    title: 'But My Mackin\' Ain\'t Easy',
    type: 'Mixtape',
    sortKey: 20
  },
  {
    cover: 'but-my-mackin-aint-easy-og.png',
    date: '2007',
    albumArtist: 'Easy Mac',
    title: 'But My Mackin\' Ain\'t Easy (Original Version)',
    type: 'Mixtape',
    additionalInfo: 'This is the original mixtape that Mac distributed in high school in 2007. Resurfaced on December 30, 2019.',
    sortKey: 0
  },
];

export const virtualAlbums: VirtualAlbum[] = [
  {
    dateRange: { start: '2024-01-19', end: '2024-12-31' },
    sortKey: 780
  },
  {
    dateRange: { start: '2024-01-01', end: '2024-01-18' },
    sortKey: 760
  },
  {
    dateRange: { start: '2023-08-03', end: '2023-12-31' },
    sortKey: 750
  },
  {
    dateRange: { start: '2023-01-19', end: '2023-08-02' },
    sortKey: 730
  },
  {
    dateRange: { start: '2023-01-01', end: '2023-01-18' },
    sortKey: 710
  },
  {
    dateRange: { start: '2022-01-01', end: '2022-12-31' },
    sortKey: 700
  },
  {
    dateRange: { start: '2021-01-20', end: '2021-12-31' },
    sortKey: 690
  },
  {
    dateRange: { start: '2021-01-01', end: '2021-01-19' },
    sortKey: 670
  },
  {
    dateRange: { start: '2020-01-17', end: '2020-12-31' },
    sortKey: 660
  },
  {
    dateRange: { start: '2020-01-01', end: '2020-01-16' },
    sortKey: 640
  },
  {
    dateRange: { start: '2019-01-01', end: '2019-12-31' },
    sortKey: 630
  },
  {
    dateRange: { start: '2018-11-28', end: '2018-12-31' },
    sortKey: 620
  },
  {
    dateRange: { start: '2018-08-03', end: '2018-11-27' },
    sortKey: 600
  },
  {
    dateRange: { start: '2018-05-30', end: '2018-08-02' },
    sortKey: 580
  },
  {
    dateRange: { start: '2018-01-01', end: '2018-05-29' },
    sortKey: 560
  },
  {
    dateRange: { start: '2017-01-01', end: '2017-12-31' },
    sortKey: 550
  },
  {
    dateRange: { start: '2016-09-16', end: '2016-12-31' },
    sortKey: 540
  },
  {
    dateRange: { start: '2016-01-01', end: '2016-09-15' },
    sortKey: 520
  },
  {
    dateRange: { start: '2015-12-30', end: '2015-12-31' },
    sortKey: 510
  },
  {
    dateRange: { start: '2015-09-18', end: '2015-12-29' },
    sortKey: 490
  },
  {
    dateRange: { start: '2015-01-01', end: '2015-09-17' },
    sortKey: 470
  },
  {
    dateRange: { start: '2014-05-11', end: '2014-12-31' },
    sortKey: 460
  },
  {
    dateRange: { start: '2014-01-01', end: '2014-05-10' },
    sortKey: 440
  },
  {
    dateRange: { start: '2013-12-17', end: '2013-12-31' },
    sortKey: 430
  },
  {
    dateRange: { start: '2013-11-01', end: '2013-12-16' },
    sortKey: 410
  },
  {
    dateRange: { start: '2013-06-20', end: '2013-10-31' },
    sortKey: 390
  },
  {
    dateRange: { start: '2013-06-18', end: '2013-06-19' },
    sortKey: 370
  },
  {
    dateRange: { start: '2013-06-10', end: '2013-06-17' },
    sortKey: 350
  },
  {
    dateRange: { start: '2013-03-04', end: '2013-06-09' },
    sortKey: 330
  },
  {
    dateRange: { start: '2013-01-01', end: '2013-03-03' },
    sortKey: 310
  },
  {
    dateRange: { start: '2012-11-21', end: '2012-12-31' },
    sortKey: 300
  },
  {
    dateRange: { start: '2012-03-23', end: '2012-11-20' },
    sortKey: 280
  },
  {
    dateRange: { start: '2012-01-01', end: '2012-03-22' },
    sortKey: 260
  },
  {
    dateRange: { start: '2011-11-24', end: '2011-12-31' },
    sortKey: 255
  },
  {
    dateRange: { start: '2011-11-08', end: '2011-11-23' },
    sortKey: 245
  },
  {
    dateRange: { start: '2011-10-14', end: '2011-11-07' },
    sortKey: 230
  },
  {
    dateRange: { start: '2011-03-29', end: '2011-10-13' },
    sortKey: 210
  },
  {
    dateRange: { start: '2011-03-11', end: '2011-03-28' },
    sortKey: 190
  },
  {
    dateRange: { start: '2011-01-01', end: '2011-03-10' },
    sortKey: 170
  },
  {
    dateRange: { start: '2010-08-13', end: '2010-12-31' },
    sortKey: 160
  },
  {
    dateRange: { start: '2010-01-01', end: '2010-08-12' },
    sortKey: 140
  },
  {
    dateRange: { start: '2009-12-01', end: '2009-12-31' },
    sortKey: 130
  },
  {
    dateRange: { start: '2009-06-01', end: '2009-11-30' },
    sortKey: 110
  },
  {
    dateRange: { start: '2009-01-01', end: '2009-05-31' },
    sortKey: 90
  },
  {
    dateRange: { start: '2008-10-27', end: '2008-12-31' },
    sortKey: 80
  },
  {
    dateRange: { start: '2008-09-12', end: '2008-10-26' },
    sortKey: 60
  },
  {
    dateRange: { start: '2008-01-01', end: '2008-09-11' },
    sortKey: 40
  },
  {
    dateRange: { start: '2007-06-01', end: '2007-12-31' },
    sortKey: 30
  },
  {
    dateRange: { start: '2007-01-01', end: '2007-05-31' },
    sortKey: 10
  }
];
