import { DatePipe as NgDatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DxAccordionModule, DxListModule, DxSwitchModule, DxTextBoxModule } from 'devextreme-angular';

import { AppComponent } from './app.component';
import { DatePipe } from './pipes/date.pipe';
import { HighlightPipe } from './pipes/highlight.pipe';
import { LengthPipe } from './pipes/length.pipe';
import { MacPipe } from './pipes/mac.pipe';
import { ReplaceSeparatorPipe } from './pipes/replace-separator.pipe';
import { TrackTypePipe } from './pipes/track-type.pipe';

@NgModule({
  declarations: [
    AppComponent,
    DatePipe,
    HighlightPipe,
    LengthPipe,
    MacPipe,
    ReplaceSeparatorPipe,
    TrackTypePipe
  ],
  imports: [
    BrowserModule,
    DxAccordionModule,
    DxListModule,
    DxSwitchModule,
    DxTextBoxModule,
    HttpClientModule
  ],
  providers: [NgDatePipe, HighlightPipe],
  bootstrap: [AppComponent]
})
export class AppModule {
}
