import { Component, OnInit, Renderer2 } from '@angular/core';

import { albums, virtualAlbums } from './data';
import { Album } from './interfaces/album.interface';
import { Filter } from './interfaces/filter.interace';
import { Track } from './interfaces/track.interface';
import { VirtualAlbum } from './interfaces/virtual-album.interface';
import { HighlightPipe } from './pipes/highlight.pipe';
import { DataService } from './services/data.service';
import { TrackType } from './types/track.type';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  /** Placeholder for missing fields. */
  public readonly missingFieldPlaceholder: string = '[none]';

  /**
   * Unfiltered discography & lists
   */

  /** Discography loaded from data source. */
  private discography: Track[];

  /** Year list generated from data source. */
  private yearList: string[];

  /** Title list generated from data source. */
  private titleList: string[];

  /** Artist list generated from data source. */
  private artistList: string[];

  /** Producer list generated from data source. */
  private producerList: string[];

  /** Featured artist list generated from data source. */
  private featuredArtistList: string[];

  /** Album artist list generated from data source. */
  private albumArtistList: string[];

  /** Album list generated from data source. */
  private albumList: string[];

  /** Type list generated from data source. */
  private typeList: TrackType[];

  /**
   * Filtered discography & lists
   */

  /** Filtered discography. */
  private filteredDiscography: Track[];

  /** Filtered year list. */
  private filteredYearList: string[];

  /** Filtered artist list. */
  private filteredArtistList: string[];

  /** Filtered producer list. */
  private filteredProducerList: string[];

  /** Filtered featured artist list. */
  private filteredFeaturedArtistList: string[];

  /** Filtered album artist list. */
  private filteredAlbumArtistList: string[];

  /** Filtered album list. */
  private filteredAlbumList: string[];

  /** Filtered type list. */
  private filteredTypeList: TrackType[];

  /**
   * Filters
   */

  /** Filters title. */
  public filtersTitle: string = 'Filters <span class="muted">(Type)</span>';

  /** Available filters. */
  public filters: Filter[];

  /** Whether or not the filters have been initialized. */
  private filtersInitialized: boolean;

  /**
   * Track count
   */

  /** Item track count for each filter. */
  public filterItemCount: {} = [];

  /** Total track count. */
  public totalTrackCount: number = 0;

  /** Filtered track count. */
  public filteredTrackCount: number = 0;

  /** Filtered track length. */
  public filteredTrackLength: string = '0 minutes';

  /**
   * Miscellaneous
   */

  /** Track groups. */
  public trackGroups: Album[] & VirtualAlbum[];

  /** Search term. */
  public searchTerm: string = '';

  /**
   * Constructor of the component.
   *
   * @param dataService Fetches data from the discography.
   * @param highlightPipe
   * @param renderer
   */
  constructor(
    private dataService: DataService,
    private highlightPipe: HighlightPipe,
    private renderer: Renderer2
  ) {
    this.highlightPipe.highlightResults = true;
  }

  /**
   * Loads the discography from data source and starts the initialization.
   */
  public ngOnInit(): void {
    this.dataService.getDiscography().subscribe((discography: Track[]) => this.init(discography));

    setTimeout(() => this.updateFilterItemCount(), 100); // initial update
  }

  /**
   * Initializes the discography.
   *
   * @param discography The discography.
   */
  private init(discography: Track[]): void {
    const d = (track: Track) => {
      const album: Album = albums.find((a: Album) => a.title === track.session);
      console.log(track, album);
      return album ? album.date : track.date;
    };

    this.discography = discography.map((track: Track) => {
      return <Track>{
        date: track.date,
        recordDate: track.session ? d(track) : track.recordDate ? track.recordDate : '',
        title: track.title,
        artist: track.artist,
        producer: track.producer === '' ? this.missingFieldPlaceholder : track.producer,
        featuredArtist: track.featuredArtist === '' ? this.missingFieldPlaceholder : track.featuredArtist,
        albumArtist: track.albumArtist === '' ? this.missingFieldPlaceholder : track.albumArtist,
        album: track.album === '' ? this.missingFieldPlaceholder : track.album,
        number: track.number,
        length: track.length,
        type: track.type,
        bonus: track.bonus,
        session: track.session ? track.session + ' sessions' : '',
        cover: track.cover
      }
    });

    this.yearList = this.dataService.getYears(this.discography);
    this.titleList = this.dataService.getProperty(this.discography, 'title');
    this.artistList = this.dataService.getProperty(this.discography, 'artist');
    this.producerList = this.dataService.getProperty(this.discography, 'producer');
    this.featuredArtistList = this.dataService.getProperty(this.discography, 'featuredArtist');
    this.albumArtistList = this.dataService.getProperty(this.discography, 'albumArtist');
    this.albumList = this.dataService.getProperty(this.discography, 'album');
    this.typeList = <TrackType[]>this.dataService.getProperty(this.discography, 'type');

    this.filteredDiscography = this.discography;
    this.filteredYearList = this.yearList;
    this.filteredArtistList = this.artistList;
    this.filteredProducerList = this.producerList;
    this.filteredFeaturedArtistList = this.featuredArtistList;
    this.filteredAlbumArtistList = this.albumArtistList;
    this.filteredAlbumList = this.albumList;
    this.filteredTypeList = this.typeList.filter((trackType: TrackType) => !(<TrackType[]>['Video']).includes(trackType));

    //this.filteredYearList = ['2010'];
    //this.filteredTypeList = this.typeList;
    //this.filteredTypeList = ['Album', 'Video'];

    this.filters = [
      {
        name: 'YearList',
        property: 'date',
        dataSource: this.yearList,
        items: this.filteredYearList,
        title: `Year <span class="muted">(${this.filteredYearList.length}/${this.yearList.length})</span>`,
        firstChange: true
      },
      {
        name: 'ArtistList',
        property: 'artist',
        dataSource: this.artistList,
        items: this.filteredArtistList,
        title: `Artist <span class="muted">(${this.filteredArtistList.length}/${this.artistList.length})</span>`,
        firstChange: true
      },
      {
        name: 'FeaturedArtistList',
        property: 'featuredArtist',
        dataSource: this.featuredArtistList,
        items: this.filteredFeaturedArtistList,
        title: `Featured Artist <span class="muted">(${this.filteredFeaturedArtistList.length}/${this.featuredArtistList.length})</span>`,
        firstChange: true
      },
      {
        name: 'ProducerList',
        property: 'producer',
        dataSource: this.producerList,
        items: this.filteredProducerList,
        title: `Producer <span class="muted">(${this.filteredProducerList.length}/${this.producerList.length})</span>`,
        firstChange: true
      },
      {
        name: 'AlbumList',
        property: 'album',
        dataSource: this.albumList,
        items: this.filteredAlbumList,
        title: `Album <span class="muted">(${this.filteredAlbumList.length}/${this.albumList.length})</span>`,
        firstChange: true
      },
      {
        name: 'AlbumArtistList',
        property: 'albumArtist',
        dataSource: this.albumArtistList,
        items: this.filteredAlbumArtistList,
        title: `Album Artist <span class="muted">(${this.filteredAlbumArtistList.length}/${this.albumArtistList.length})</span>`,
        firstChange: true
      },
      {
        name: 'TypeList',
        property: 'type',
        dataSource: this.typeList,
        items: this.filteredTypeList,
        title: `Type <span class="muted">(${this.filteredTypeList.length}/${this.typeList.length})</span>`,
        firstChange: true
      }
    ];

    this.filtersInitialized = true;

    this.totalTrackCount = this.discography.length;

    this.updateFilterItemCount(); // init filter template

    this.updateTrackList(this.discography);
  }

  /**
   * Updates the track list.
   *
   * @param tracks  List of tracks.
   */
  private updateTrackList(tracks: Track[]): void {
    this.filteredTrackLength = this.dataService.getTotalLength(tracks);

    this.trackGroups = [];

    albums.forEach((album: Album) => {
      album.tracks = this.dataService.getAlbumTracks(tracks, album);
      album.totalLength = this.dataService.getTotalLength(album.tracks);
      this.trackGroups.push(album);
    });

    virtualAlbums.forEach((virtualAlbum: VirtualAlbum) => {
      virtualAlbum.date = virtualAlbum.dateRange.start;
      virtualAlbum.tracks = this.dataService.getVirtualAlbumTracks(tracks, virtualAlbum);
      this.trackGroups.push(virtualAlbum);
    });

    this.trackGroups.sort((a: Album | VirtualAlbum, b: Album | VirtualAlbum) => {
      return this.dataService.chronologicalOrderAscending
        ? a.sortKey - b.sortKey
        : b.sortKey - a.sortKey;
    });

    this.countTracks();
    this.setYears();
  }

  /**
   * Counts the tracks.
   */
  private countTracks(): void {
    requestAnimationFrame(() => {
      this.filteredTrackCount = document.querySelectorAll('.track').length;
    });
  }

  /**
   * Sets the year headers.
   */
  private setYears(): void {
    requestAnimationFrame(() => {
      const years: string[] = [];

      document.querySelectorAll('.year').forEach((year: HTMLDivElement) => {
        year.parentNode.removeChild(year);
      });

      document.querySelectorAll('[data-date]').forEach((trackGroup: HTMLDivElement) => {
        const year: string = trackGroup.dataset.date.substr(0, 4);

        if (!years.includes(year)) {
          let element: HTMLDivElement = this.renderer.createElement('div');
          this.renderer.addClass(element, 'year');
          this.renderer.setProperty(element, 'innerHTML', year);
          this.renderer.insertBefore(trackGroup.parentNode, element, trackGroup.parentNode.firstChild);
          years.push(year);
        }
      });
    });
  }

  /**
   * Filters the tracks.
   *
   * @param type  Filter type.
   * @param items Filtered items.
   */
  public filter(type: string, items: string[]): void {
    this['filtered' + type] = items;

    this.filteredDiscography = this.discography.filter((track: Track) => {
      return (
        this.filteredYearList.includes(track.date.substr(0, 4)) &&
        //this.filteredArtistList.includes(track.artist) &&
        //this.filteredProducerList.includes(track.producer) &&
        //this.filteredFeaturedArtistList.includes(track.featuredArtist) &&
        //this.filteredAlbumArtistList.includes(track.albumArtist) &&
        this.filteredArtistList.some(r => track.artist.split(';').includes(r)) &&
        this.filteredProducerList.some(r => track.producer.split(';').includes(r)) &&
        this.filteredFeaturedArtistList.some(r => track.featuredArtist.split(';').includes(r)) &&
        this.filteredAlbumArtistList.some(r => track.albumArtist.split(';').includes(r)) &&
        //this.arrayContainsArray(this.filteredArtistList, track.artist.split(';')) &&
        //this.arrayContainsArray(this.filteredProducerList, track.producer.split(';')) &&
        //this.arrayContainsArray(this.filteredFeaturedArtistList, track.featuredArtist.split(';')) &&
        //this.arrayContainsArray(this.filteredAlbumArtistList, track.albumArtist.split(';')) &&
        this.filteredAlbumList.includes(track.album) &&
        this.filteredTypeList.includes(track.type)
      );
    });

    this.updateFilterTitle();
    this.updateFilterTitleCount(type, items.length);

    ////////////////////////////////////////////////////////////////////////////////

    const filter: Filter = this.filters.find((filter: Filter) => filter.name === type);

    if (!filter.firstChange) { // skip on filter template initialization
      this.updateFilterItemCount();
    }

    filter.firstChange = false;

    ////////////////////////////////////////////////////////////////////////////////

    this.updateTrackList(this.filteredDiscography);
    this.search(this.searchTerm);
  }

  /*
  private arrayContainsArray(superset, subset): boolean {
    if (0 === subset.length) {
      return false;
    }
    return subset.every(function (value) {
      return (superset.indexOf(value) >= 0);
    });
  }
  */

  /**
   * Updates the filter title.
   */
  private updateFilterTitle(): void {
    let activeFilters: string[] = [];

    this.filters.forEach((filter: Filter) => {
      if (filter.dataSource.length !== this['filtered' + filter.name].length) {
        activeFilters.push(filter.title.split(' <')[0]);
      }
    });

    this.filtersTitle = activeFilters.length ?
      `Filters <span class="muted">(${activeFilters.join(', ')})</span>` :
      'Filters';
  }

  /**
   * Updates the filter title count.
   *
   * @param type    Filter type.
   * @param length  Number of filtered items.
   */
  private updateFilterTitleCount(type: string, length: number): void {
    if (!this.filtersInitialized) {
      return;
    }

    const filterType: Filter = this.filters.find((filterType: Filter) => filterType.name === type);
    const title: string = filterType.title.split(' <')[0];

    filterType.title = `${title} <span class="muted">(${length}/${filterType.dataSource.length})</span>`;
  }

  /**
   * Updates the filter item count.
   */
  private updateFilterItemCount(): void {
    this.filterItemCount = this.dataService.getFilterItemCount(this.discography, this.filteredDiscography, this.filters);
  }

  /**
   * Searches for a track.
   *
   * @param searchTerm  Search term to search for.
   */
  public search(searchTerm: string): void {
    this.searchTerm = searchTerm.replace(/;/g, '').toLowerCase();

    let tracks: Track[] = this.filteredDiscography.filter((track: Track) => {
      return (
        track.title.toLowerCase().includes(this.searchTerm) ||
        track.artist.toLowerCase().includes(this.searchTerm) ||
        track.producer.toLowerCase().includes(this.searchTerm) ||
        track.featuredArtist.toLowerCase().includes(this.searchTerm) ||
        track.albumArtist.toLowerCase().includes(this.searchTerm)) ||
        track.album.toLowerCase().includes(this.searchTerm) ||
        track.session.toLowerCase().includes(this.searchTerm);
    });

    this.updateTrackList(tracks);
  }

  /**
   * Toggles the chronological order.
   */
  public toggleChronologicalOrder(): void {
    this.dataService.chronologicalOrderAscending = !this.dataService.chronologicalOrderAscending;
    this.search(this.searchTerm);
  }

  /**
   * Toggles the sorting date.
   */
  public toggleSortingDate(): void {
    this.dataService.sortedByRecordDate = !this.dataService.sortedByRecordDate;
    this.search(this.searchTerm);
  }

  /**
   * Checks if a track is a posthumous leak.
   *
   * @param track  The Track.
   */
  public isPosthumousLeak(track: Track): boolean {
    const deathDate: string = '2018-09-07';
    return this.dataService.sortedByRecordDate && track.recordDate && new Date(track.date).getTime() > new Date(deathDate).getTime();
  }

  /**
   * Gets the cover URL.
   *
   * @param track The Track.
   */
  public getCoverUrl(track: Track): string {
    if (track.cover) {
      return <string>track.cover;
    }

    let number: string = track.number ?
      track.number.toString().padStart(2, '0') :
      '';

    let title: string = track.title
      .replace(':', '')
      .replace('/', '')
      .replace('#', '');

    let prefix: string = track.type === 'Compilation' ?
      `${number}.` :
      `[${track.date}]`;

    let artist: string = track.artist
      .replace(/;([^;]*)$/, ' & $1')
      .replace(/;/g, ', ');

    let featuredArtist: string = track.featuredArtist
      .replace(/;([^;]*)$/, ' & $1')
      .replace(/;/g, ', ');

    return track.featuredArtist !== this.missingFieldPlaceholder ?
      `${prefix} ${artist} - ${title} (feat. ${featuredArtist}).jpeg` :
      `${prefix} ${artist} - ${title}.jpeg`;
  }

}
