import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe as NgDatePipe } from '@angular/common';

@Pipe({
  name: 'date'
})
export class DatePipe implements PipeTransform {

  constructor(private datePipe: NgDatePipe) {
  }

  transform(date: string, prefix?: boolean): string {
    if (date.length === 4) {
      if (date === '2013') {
        return 'Recorded in 2013.';
      }

       if (date === '2014') {
        return 'Recorded in 2013/2014.';
      }

      return prefix ? 'Released in ' + date + '.' : date;
    }

    if (date.length === 7) {
      return prefix ?
        'Released in ' + this.datePipe.transform(date, 'MMMM, yyyy') + '.' :
        this.datePipe.transform(date, 'MMM yyyy');
    }

    return prefix ?
      'Released on ' + this.datePipe.transform(date, 'MMMM d, yyyy') + '.' :
      this.datePipe.transform(date, 'MMM dd yyyy');
  }

}
