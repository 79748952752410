import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'length'
})
export class LengthPipe implements PipeTransform {

  transform(length: string): string {
    if (!length) {
      return;
    }

    if (length.substr(0, 1) === '0') {
      return length.substr(1, length.length - 1);
    }

    return length;
  }

}
