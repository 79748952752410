import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {

  public highlightResults: boolean;

  constructor(private domSanitizer: DomSanitizer) {
  }

  transform(value: string, args?: string): SafeHtml {
    if (!args || !this.highlightResults) {
      return value;
    }

    const regex: RegExp = new RegExp(args.replace('.', '\\.'), 'gi');

    return this.domSanitizer.bypassSecurityTrustHtml(value.replace(regex, '<mark>$&</mark>'));
  }

}
