import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Album } from '../interfaces/album.interface';
import { Filter } from '../interfaces/filter.interace';
import { Track } from '../interfaces/track.interface';
import { VirtualAlbum } from '../interfaces/virtual-album.interface';
import { TrackType } from '../types/track.type';

/**
 * Fetches data from the discography.
 */
@Injectable({
  providedIn: 'root'
})
export class DataService {

  /** Whether the tracks are sorted chronologically ascending. */
  public chronologicalOrderAscending: boolean = false;

  /** Whether the tracks are sorted by record date. */
  public sortedByRecordDate: boolean = false;

  /**
   * Constructor of the service.
   *
   * @param http  Performs HTTP requests.
   */
  constructor(private http: HttpClient) {
  }

  /**
   * Gets the discography.
   */
  public getDiscography(): Observable<Track[]> {
    return this.http.get<Track[]>('/assets/discography.json');
  }

  /**
   * Gets an unique list of years from a list of tracks.
   *
   * @param tracks  List of tracks.
   */
  public getYears(tracks: Track[]): string[] {
    const years: string[] = tracks.map((track: Track) => {
      return track.date.substr(0, 4);
    });

    return [...new Set(years)].sort().reverse();
  }

  /**
   * Gets an unique list for the given property from a list of tracks.
   *
   * @param tracks        List of tracks.
   * @param propertyName  Name of the property.
   */
  public getProperty(tracks: Track[], propertyName): string[] {
    const results: string[] = [];

    tracks.forEach((track: Track) =>
      track[propertyName].includes(';') ?
        track[propertyName].split(';')
          // add each item of semicolon-separated string
          .forEach((property: string) => results.push(property)) :
        results.push(track[propertyName]));

    //const results: string[] = tracks.map((track: Track) => track[propertyName]);
    return [...new Set(results)] ?
      [...new Set(results)].sort((a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase())) :
      [];
  }

  /**
   * Gets the total and filtered item count for each filter.
   *
   * @param tracks          List of tracks.
   * @param filteredTracks  Filtered list of tracks.
   * @param filters         The filter.
   */
  public getFilterItemCount(tracks: Track[], filteredTracks: Track[], filters: Filter[]): {}[] {
    const filterItemCount: {}[] = [];

    filters.forEach((filter: Filter) => {
      const filterCount: {} = {};

      filter.dataSource.forEach((filterItem: string) => {
        filterCount[filterItem] = {};

        filter.name === 'YearList' ?
          filterCount[filterItem]['total'] = tracks.filter((track: Track) => track.date.substr(0, 4) === filterItem).length :
          filterCount[filterItem]['total'] = tracks.filter((track: Track) => track[filter.property].split(';').includes(filterItem)).length;

        filter.name === 'YearList' ?
          filterCount[filterItem]['filtered'] = filteredTracks.filter((track: Track) => track.date.substr(0, 4) === filterItem).length :
          filterCount[filterItem]['filtered'] = filteredTracks.filter((track: Track) => track[filter.property].split(';').includes(filterItem)).length;
      });

      filterItemCount[filter.name] = filterCount;
    });

    return filterItemCount;
  }

  /**
   * Gets the tracks for a given album from a list of tracks.
   *
   * @param tracks  List of tracks.
   * @param album   The album.
   */
  public getAlbumTracks(tracks: Track[], album: Album): Track[] {
    return tracks.filter((track: Track) => track.album === album.title);
  }

  /**
   * Gets the tracks for a given virtual album from a list of tracks.
   *
   * @param tracks        List of tracks.
   * @param virtualAlbum  The virtual album.
   */
  public getVirtualAlbumTracks(tracks: Track[], virtualAlbum: VirtualAlbum): Track[] {
    return tracks
      .filter((track: Track) => {
        return (
          new Date(this.getDate(track)).getTime() >= new Date(virtualAlbum.dateRange.start).getTime() &&
          new Date(this.getDate(track)).getTime() <= new Date(virtualAlbum.dateRange.end).getTime() &&
          (<TrackType[]>['Single', 'Feature', 'Producer', 'Compilation', 'Video', 'Snippet']).includes(track.type));
      })
      .reverse()
      .sort((a: Track, b: Track) => {
        return a.title.localeCompare(b.title);
      })
      .sort((a: Track, b: Track) => {
        return a.number - b.number;
      })
      .sort((a: Track, b: Track) => {
        return this.chronologicalOrderAscending
          ? new Date(this.getDate(a)).getTime() - new Date(this.getDate(b)).getTime()
          : new Date(this.getDate(b)).getTime() - new Date(this.getDate(a)).getTime();
      });
  }

  /**
   * Gets the total length for a list of tracks.
   *
   * @param tracks  List of tracks.
   */
  public getTotalLength(tracks: Track[]): string {
    let seconds: number = 0;

    tracks.forEach((track: Track) => {
      if (track.length) {
        const parts: string[] = track.length.split(':');

        parts.length === 2 ?
          seconds += (parseInt(parts[0]) * 60) + (parseInt(parts[1])) : // (minutes * 60) + seconds
          seconds += (parseInt(parts[0]) * 60 * 60) + (parseInt(parts[1]) * 60) + (parseInt(parts[2])); // (hours * 60 * 60) + (minutes * 60) + seconds
      }
    });

    //return new Date(seconds * 1000).toISOString().substr(11, 8);
    const sum: number = Math.round(seconds / 60);
    return sum + (sum === 1 ? ' minute' : ' minutes');
  }

  /**
   * Gets the date for a given track.
   *
   * @param track   The track.
   */
  public getDate(track: Track): string {
    return this.sortedByRecordDate
      ? track.recordDate || track.date
      : track.date;
  }

}
